import React from 'react';
import data from '../data/data.json';

import { Button } from '../components/atoms/button';

const { pages, subjects, subCategories, subAreas, subQuestions } = data;

// markup
function DataPage() {
	return (
		<main id="main">
			<h1>Quickly find the right resources for your needs</h1>
			{pages.map((page, index) => (
				<>
					<h2>Page {index + 1}</h2>
					<ul>
						{page.questions.map((question) => (
							<li key={`main-question-${question.id}`}>
								<h2>{question.question}</h2>
								{question.description ? (
									<h3>{question.description}</h3>
								) : null}

								{question.answers.length > 0 ? (
									<ul>
										{question.answers.map((answer) => (
											<li
												key={`main-question-${question.id}-answer-${answer.id}`}
											>
												<Button type="button">
													{answer.answer}
												</Button>
											</li>
										))}
									</ul>
								) : null}
							</li>
						))}
					</ul>
				</>
			))}
			<h2>Subjects</h2>
			<ul>
				{subjects.map((subject) => (
					<li key={`subject-${subject.id}`}>{subject.name}</li>
				))}
			</ul>
			<h2>Subcategories</h2>
			<ul>
				{subCategories.map((subCategory) => (
					<li key={`subcategory-${subCategory.id}`}>
						{subCategory.name}
					</li>
				))}
			</ul>
			<h2>Subareas</h2>
			<ul>
				{subAreas.map((subArea) => (
					<li key={`subarea-${subArea.id}`}>{subArea.name}</li>
				))}
			</ul>
			<h2>Sub Questions</h2>
			<ul>
				{subQuestions.map((subQuestion) => (
					<li key={`subquestion-${subQuestion.id}`}>
						<h3>{subQuestion.question}</h3>

						{subQuestion.answers.length > 0 ? (
							<ul>
								{subQuestion.answers.map((answer) => (
									<li
										key={`subquestion-${subQuestion.id}-answer-${answer.id}`}
									>
										<Button type="button">
											{answer.answer}
										</Button>
									</li>
								))}
							</ul>
						) : null}
					</li>
				))}
			</ul>
		</main>
	);
}

export default DataPage;
